 
import React from 'react'
import './css/contacts.scss'
import {textSpliter} from './js/textSpliter.js'

function contactCell(header , body , url) {
  let splitedText = textSpliter(body, '\n');
  return (
    <div className='contactCellContentWrapper'> 
      <div className='contactInfoContent'>
        <div className='contactInfoCellImgWrapper'>
          <div className='contactInfoCellImg' style={{backgroundImage: 'url("img/'+url+'")'}}></div> 
        </div>
        <div>
          <h3 className='contactInfoHeader'>{header}</h3>
          <div className='contactInfoBody'>{splitedText}</div>
        </div>
      </div> 
    </div>
  )
}

const Contacts = () => {
  return (
    <div id="contacts" className="home">
      <div className="timeline">
        <h2 className='contactHeader'> 
          <img src='./img/contacts.png' alt="" height={40} width={40}/>
          <span> Contacts </span> 
        </h2>
        <div className="container right" > 
          <div className="content">
            <div className='contactInfoCellWrapper'>  
              {contactCell('E-MAIL', 'leonid.gurockin@gmail.com' , './email.png' )}
              {contactCell('PHONE', ' +44 7591493281 (UK)', './phone.png')}
              {contactCell('ADDRESS', 'REVERE WAY \n  EPSOM, KT19 9RQ' , './home.png')}
            </div>
	   <iframe title='address' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d523.8599842128252!2d-0.2604556536835634!3d51.34952727603624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487609e0ffd8fa19%3A0x44bf02e6e01fe0d8!2s2%20Revere%20Way%2C%20Epsom%20KT19%209RQ!5e0!3m2!1slv!2suk!4v1645488360993!5m2!1slv!2suk"  width="100%" height="450" style={{border: '0'}} allowFullScreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}
export {Contacts}