import React from 'react'
import './css/homePage.scss'
import {Link} from "react-scroll";
import {textSpliter} from './js/textSpliter.js'
const Homepage = () => {
  const mainPageText = `
  I am a second-year student with a strong background in full-stack web development and a passion for coding, software, and game development. 
  
  I possess a collaborative spirit and a strong desire to expand my knowledge. 
  
  Currently, I am pursuing a degree in game and software development and am actively seeking opportunities to challenge and enhance my skills.

**My Interests:**
- **Game Development:** I worked on personal projects using Unreal Engine 5 and the GTA 5 Rage Engine.
- **Sports Activities:** I enjoy gym calisthenics, roller skating, and volleyball.
- **Music:** I play the guitar.
- **Board and Video Games:** I love D&D-style board games and various PC RPGs, such as Gothic, Divinity, Neverwinter Nights, and Baldur's Gate 3.

 `;
 let splitedText = textSpliter(mainPageText, '\n');

  return (
    <div id="homePage" className="homePage " style={{backgroundImage: 'url("./img/bg.jpg")',}}>
      <div className='mainPageAboutWrapper'>
        <h1 className='mainPageHeader'>JUNIOR WEB DEVELOPER</h1>
        <div className='aboutText'>
          {splitedText}
        </div>
        <Link to='skills' spy={true} hashSpy={true} smooth={true} offset={-50} duration={500}> 
          <button className='mainPageSkillBtn'>SKILLS</button>
        </Link>
      </div>
    </div>
  )
}
export {Homepage}