import React, { useEffect  } from 'react';
import './css/skills.scss'
import ProgressBar from '../components/skills/progressBar.jsx'
import animation from './js/animation.js'

const Skills = () => {
  useEffect(() => { 
    //for animation reset on link copy
    var x = document.querySelectorAll(".square");
    x.forEach((i) => {
      i.classList.add('square-animation');
    })
    animation('.square' , 'square-animation', '.square-wrapper')
  }, []);

 function wrapper(name , skills ){
   return (
    <div className="container right" > 
      <div className="content flexJustifyCenter skillsSectionWrapper">
        <div className='skillHeaderWrapper'>
          <div className='imgUrl' style={{backgroundImage: 'url("'+ skills[0].imgUrl +'")'}}></div>
          <h4 className="skillHeader"> {name}</h4>
        </div>
        <div className='skillsSectionContent'>
          {skills.map((skill, index) => {
            return (
              <ProgressBar value={skill.value} name={skill.name}  key={index}/>
            )}
          )}
        </div>
      </div>
    </div>
   )
 }


 let gamesSkills = [
  {'value' : 60 , 'name': 'C++' , 'imgUrl' : './img/backendLogo.png'  },
  {'value' : 60 , 'name': 'UE5' },
  {'value' : 70 , 'name': 'C#' },
  {'value' : 70 , 'name': 'UNITY' },
];

  let backendSkills = [
    {'value' : 60 , 'name': 'PHP' , 'imgUrl' : './img/backendLogo.png'  },
    {'value' : 60 , 'name': 'NodeJs' },
    {'value' : 70 , 'name': 'MySql' },
  ];

  let frontendSkills = [
    {'value' : 90 , 'name': 'HTML' , 'imgUrl' : './img/frontendLogo.png'  },
    {'value' : 80 , 'name': 'CSS' },
    {'value' : 70 , 'name': 'SASS' },
    {'value' : 70 , 'name': 'JS' },
    {'value' : 80 , 'name': 'JQUERY' },
    {'value' : 50 , 'name': 'VUEJS' },
    {'value' : 50 , 'name': 'REACTJS' },
  ];

  let otherSkills = [
    {'value' : 65 , 'name': 'GIT' , 'imgUrl' : './img/otherSkills.png'  },
    {'value' : 70 , 'name': 'JSON'},
    {'value' : 50 , 'name': 'FIGMA'},
  ];

  let languageSkills = [
    {'value' : 65 , 'name': 'English' , 'imgUrl' : './img/language-icon.png'  },
    {'value' : 100 , 'name': 'Russian' },
    {'value' : 49 , 'name': 'Latvian' },
  ];
  return (
  <div id="skills" >
    <div className="timeline">
    <h2 className='skillPageHeader'> 
      <img src='./img/skills.png' alt="" height={40} width={40}/>
      <span> Skills</span> 
    </h2>
      {wrapper('Games', gamesSkills)}
      {wrapper('BackEnd', backendSkills)}
      {wrapper('FrontEnd', frontendSkills)}
      {wrapper('Other', otherSkills)}
      {wrapper('Languages', languageSkills)}
    </div>
  </div>
  
  )
}
export {Skills}