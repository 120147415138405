import React, { useEffect , useState } from 'react';
import './css/portfolio.scss'
import PopUp from '../components/popUp/popUp.jsx'

const Portfolio = () => {
  const [count1, setCount] = useState([]);
  const [count2, setCount2] = useState(123);
  const links = [];
  links[0] = "https://leogurockin.info/backend/work3.php";
  links[1] = "http://localhost/backend/work3.php";
  useEffect(() => {
    fetch(links[0])
    .then(response => response.json())
    .then(data => setCount(data)
    );
  }, []);

  function popUp(data) {
    setCount2(data)
    document.getElementById("myNav").style.width = "100%";
  }

  function portfolioSection(year) 
  {
    return (
      <div className="container right" > 
        <div className="content portfolioWrapper" >
          <div className='yearSection'>{year}</div>
          <div className='portfolioCellWrapper'>
            {count1.map((educations, index) => {
              if(educations.Partition === year) {
                return (
                  <div className='portfolioCell'  key={index}>
                    <h4 className='portfolioHeader'>{educations.Name}</h4>
                    <div className='portfolioLogoWrapper'>
                      <img className='portfolioLogo' src={"img/" + educations.logo} width={100} alt="" />
                    </div>
                    <p className='ShortDescription'>{educations.ShortDescription}</p>
                    <button className='readMoreBtn' onClick={()=> popUp(educations)}>Read More</button>
                  </div>
                )
              } else {return(null)}
            })}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div id="portfolio"  className="home">
      <div className="timeline">
        <PopUp propa={count2}/>
        <h2 className='portfolioPageHeader'> 
          <img src='./img/portfolio-icon.png' alt="" height={40} width={40}/>
          <span> Portfolio</span> 
        </h2>
        {portfolioSection('GAMES')}
        {portfolioSection('WEB')}
   
      </div>
    </div>
  )
}
export {Portfolio}