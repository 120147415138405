import './popUp.scss';
import PopUpImg from './photoSlider';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faDotCircle, faBookReader } from '@fortawesome/free-solid-svg-icons';
import { textSpliter } from '../../views/js/textSpliter.js';

const PopUp = (props) => {
  const [tehnologiesSplited, setTehnologiesSplited] = useState([]);
  const [Responsibilities, setResponsibilities] = useState([]);
  const [splitedDescription, setSplitedDescription] = useState([]);
  const [images, setImages] = useState([]);
  const [image, setImage] = useState([]);
  const [loadingImages, setLoadingImages] = useState({}); // Tracks loading state for each image

  // Update content based on props
  useEffect(() => {
    if (props.propa.Responsibilities) {
      setResponsibilities(props.propa.Responsibilities.split('\n'));
      setSplitedDescription(textSpliter(props.propa.Body, '\n'));
      setTehnologiesSplited(props.propa.Technologies.split(','));
    }

    // Update images when imgLink changes
    if (props.propa.imgLink) {
      const parsedImages = JSON.parse(props.propa.imgLink);
      setImages(parsedImages);
      const initialLoadingState = parsedImages.reduce((acc, img, index) => {
        acc[index] = true; // Initially, all images are loading
        return acc;
      }, {});
      setLoadingImages(initialLoadingState);
    } else {
      setImages([]); // Clear images if none are provided
      setLoadingImages({});
    }
  }, [props]);

  // Close the popup and clear state
  function closeNav() {
    document.getElementById('myNav').style.width = '0%';
    setImages([]); // Clear images
  }

  // Show a specific image
  function showimg(imgArray, index) {
    imgArray.index = index;
    document.getElementById('myNav2').style.width = '100%';
    setImage(imgArray);
  }

  // Listen for Escape key to close the popup
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        let test = document.getElementById('myNav2').offsetWidth;
        if (test === 0) {
          closeNav();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Handle image load to toggle loader off
  const handleImageLoad = (index) => {
    setLoadingImages((prev) => ({ ...prev, [index]: false }));
  };

  // Helper function to render a cell
  function cell(header, body) {
    return (
      <div className="popUpcellWrapper">
        <div className="popUpCellHeader">{header}: </div>
        <div className="popUpCellBody">{body}</div>
      </div>
    );
  }

  return (
    <div id="myNav" className="overlay">
      <span className="closebtn" onClick={closeNav}>
        X
      </span>
      <PopUpImg img={image} />
      <div className="overlay-content">
        <h2 className="popUpHeader">
          <FontAwesomeIcon className="popUpHeaderIcon" icon={faBookReader} /> {props.propa.Name}
        </h2>
        <div className="popUpWrapper">
          <div className="popUpImagesSectionWrapper">
            <div className="popUpImagesSectionContent">
              {images.map((imgSrc, index) => (
                <div
                  className="popUpImages"
                  onClick={() => showimg(images, index)}
                  key={index}
                >
                  {loadingImages[index] && (
                    <div className="loader">Loading...</div>
                  )}
                  <img
                    className={`popUpimage ${loadingImages[index] ? 'hidden' : ''}`}
                    src={`img/${imgSrc.img}`}
                    alt=""
                    onLoad={() => handleImageLoad(index)} // Mark image as loaded
                  />
                </div>
              ))}

              {props.propa.video && <YouTubeEmbed link={props.propa.link} />}
            </div>
          </div>
          <div className="popUpRightBarWrapper">
            {cell('Client', props.propa.Client)}
            <div className="popUpRightBarContent">
              <div className="rightBarLinkHeader">Link: </div>
              <div>
                <a
                  className="rightBarLink"
                  href={props.propa.linkName}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.propa.linkName}
                </a>
              </div>
            </div>

            {/* Technologies Section */}
            <div className="tehnologiesWrapper">
              <div className="tehnologiesHeader">Technologies: </div>
              <div className="tehnologiesCellWrapper">
                {tehnologiesSplited.map((lineOfText, index) => (
                  <div className="lineOfText" key={index}>
                    <FontAwesomeIcon className="popUpIcons" icon={faDotCircle} />{' '}
                    {lineOfText}
                  </div>
                ))}
              </div>
            </div>

            {cell(
              'GitHub',
              <a href={props.propa.pull} target="_blank" rel="noreferrer">
                {props.propa.pull}
              </a>
            )}
            {cell('Description', splitedDescription)}

            {/* Responsibilities Section */}
            <div className="featuresHeader">Main Features: </div>
            <div className="featuresWrapper">
              {Responsibilities.map((lineOfText, index) => (
                <p className="lineOfText" key={index}>
                  <FontAwesomeIcon className="featuresIcons" icon={faCheck} />{' '}
                  {lineOfText}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const YouTubeEmbed = ({ link }) => {
  return (
    <iframe
      width="560"
      height="315"
      src={link}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
      referrerPolicy="no-referrer"
      allowFullScreen
    ></iframe>
  );
};

export default PopUp;
