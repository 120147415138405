import React, { useEffect , useState } from 'react';
import './css/work.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import animation from './js/animation.js'
import {textSpliter, linkSpliter} from './js/textSpliter.js'
const briefcase = <FontAwesomeIcon icon={faBriefcase} />
const Work = () => {
  const links = [];
  links[0] = "https://leogurockin.info/backend/work2.php";
  links[1] = "http://localhost/backend/work2.php";

  const [count, setCount] = useState([]);
  useEffect(() => {
    fetch(links[0])
    .then(response => response.json())
    .then(data => setCount(data.reverse())
    );
  }, []);
  
  useEffect(() => 
  {
    animation('.test' , 'workAnimation' , '.testwrapper')
  })
  
  return (
    <div id="work" className="workExperience">
      <div className="timeline">
        <h2 className='workPageHeader'> 
          <img src='./img/work-expirience-icon.png' alt="" height={40} width={40}/>
          <span> Experience </span> 
        </h2>
        {count.map((work, index) => 
        {
          let splitedText = textSpliter(work.Body, '\n');
          let links = linkSpliter(work.link, work.linkName, '\n' )
          return (
            <div className="container right  testwrapper"  key={index}> 
              <div className="content test">
                <h4 className='workRowHeader'>
                  {briefcase}
                  {work.RowName}
                </h4>
                {splitedText}
                {links}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export {Work}